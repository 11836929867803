"use client";
import { useEffect, useLayoutEffect } from "react";

import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import styles from "./device.module.css";

type Props = {
  children?: React.ReactNode;
};

const useBrowserLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

const Device: React.FC<Props> = ({ children }) => {
  const { setIsMobile, setIsDesktop, setHasWindow } = usePersistedStore(
    (state) => ({
      setIsMobile: state.setIsMobile,
      setIsDesktop: state.setIsDesktop,
      setHasWindow: state.setHasWindow,
      setPreview: state.setPreview,
      environment: state.environment
    })
  );

  const maxMobileSize = styles.maxMobilesize || "999px";
  const mobileSizeMatch = maxMobileSize.match(/^d+/);
  const mobileSizeNumber =
    mobileSizeMatch && mobileSizeMatch[0] ? +mobileSizeMatch[0] : 999;

  useBrowserLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileSizeNumber);
      setIsDesktop(window.innerWidth > mobileSizeNumber);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <>{children}</>;
};

export default Device;
