"use client";
/**
 * DO NOT REMOVE THIS LINE.
 * Imports all global css and bloks in correct import order
 */
import "@/lib/bloks/initialiseBloks";

import { ExitPreviewMode } from "@/components/atoms/ExitPreviewMode";
import config from "@/config";
import LinkMapLoader from "@/lib/bloks/LinkMapLoader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NextIntlClientProvider } from "next-intl";
import type { ReactNode } from "react";
import { Suspense, useState } from "react";

interface IProvidersProps {
  children: ReactNode;
  locale: string;
  messages: Record<string, string>;
  links?: Record<string, string>;
  previewMode?: boolean;
}

export default function Providers({
  children,
  locale,
  messages,
  links,
  previewMode
}: IProvidersProps) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <>
      <LinkMapLoader links={links}>
        <QueryClientProvider client={queryClient}>
          <NextIntlClientProvider
            locale={locale}
            messages={messages}
            timeZone={config.timeZone}
          >
            {children}
          </NextIntlClientProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LinkMapLoader>
      {previewMode && (
        <Suspense>
          <ExitPreviewMode previewEnabled={previewMode} />
        </Suspense>
      )}
    </>
  );
}
