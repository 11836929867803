"use client";

import ProductCard from "@/components/atoms/ProductCard";
import type { ProductCard as iProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import useDevice from "@/lib/hooks/useDevice";
import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../product-listing.module.css";

type iCard = {
  card: iProductCard;
};

const ContentSlides = ({ cards }: { cards: iCard[] }) => {
  const { desktop } = useDevice();

  return (
    <Swiper
      spaceBetween={16}
      slidesPerView={"auto"}
      scrollbar={{ draggable: true }}
      navigation={!!desktop}
      modules={[Navigation, A11y]}
      className={styles.swiper}
    >
      {cards
        .filter(
          (card) => card.card?.core && !!Object.keys(card.card.core).length
        )
        .map((card, key) => (
          <SwiperSlide key={key} className={styles.slide}>
            <ProductCard product={card?.card} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default ContentSlides;
