"use client";

import ArrowRight from "@/icons/ArrowRight";
import { useCallback, useEffect, useState } from "react";
import styles from "./index.module.css";

export const ExitPreviewMode = ({
  previewEnabled
}: {
  previewEnabled: boolean;
}) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!previewEnabled) return;

    // Check if we are in iFrame
    if (window.location !== window.parent.location) return;

    // We are not in iFrame, exit preview mode
    setShouldRender(true);
  }, [previewEnabled]);

  const handleExit = useCallback(() => {
    const url = new URL("/api/preview/disable", window.location.origin);
    url.searchParams.set("slug", window.location.pathname);
    window.location.href = url.href;
  }, []);

  if (!shouldRender) return null;
  return (
    <button className={styles.root} onClick={handleExit}>
      <ArrowRight /> <span>Exit preview mode</span>
    </button>
  );
};
