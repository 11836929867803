"use client";
import { useMemo } from "react";
import { globalLinkMap } from "../storyblok/utils/linkMap";

/**
 * This populates the global link map on the client side.
 */
const LinkMapLoader = ({
  children,
  links
}: {
  children: React.ReactNode;
  links?: Record<string, string>;
}) => {
  /**
   * Technically a hack, but this is the only way to populate the global link map without breaking hydration
   */
  useMemo(() => {
    if (!links) return;
    Object.entries(links).forEach(([uuid, url]) => {
      globalLinkMap.set(uuid, url);
    });
  }, [links]);

  return <>{children}</>;
};

export default LinkMapLoader;
